'use client';
import {api} from '@/services/api';
import {
  cartActionsReducer,
  cartsReducer,
  deliveryMethodReducer,
  giftCardReducer,
  guestReducer,
  modalActionsReducer,
  tokenReducer,
  userReducer,
} from '@/slices';
import deliveryReducer from '@/slices/deliverySlice';
import lastPaidOrderReducer from '@/slices/lastPaidOrderSlice';
import paymentReducer from '@/slices/paymentSlice';
import recentlyViewedProductsReducer from '@/slices/recentlyViewedProductsSlice';
import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage();

const persistConfig = {
  timeout: 2000,
  key: 'root',
  storage,
  whitelist: [
    'carts',
    'delivery',
    'deliveryMethodActions',
    'guest',
    'token',
    'lastPaidOrder',
  ],
};

const reducers = combineReducers({
  api: api.reducer,
  carts: cartsReducer,
  token: tokenReducer,
  recentlyViewedProducts: recentlyViewedProductsReducer,
  cartActions: cartActionsReducer,
  modalActions: modalActionsReducer,
  deliveryMethodActions: deliveryMethodReducer,
  delivery: deliveryReducer,
  guest: guestReducer,
  lastPaidOrder: lastPaidOrderReducer,
  payment: paymentReducer,
  giftCard: giftCardReducer,
  user: userReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    const defaultMiddleWares = [api.middleware];
    const middleWares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(defaultMiddleWares);

    return middleWares;
  },
});

const persistor = persistStore(store);

export {persistor, store};

setupListeners(store.dispatch);
